.playlist {
  display: flex;
  flex-direction: column;
  align-items:  center;

  .title {
    font-size: 2rem;
    margin: 2rem 0 1rem 0; }

  .playlist-item {
    display: flex;
    margin: 1rem;
    padding: 1rem 2rem;
    width: 90%;
    min-width: 200px;
    max-width: 600px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 6px; } }
